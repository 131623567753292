
  export default {
    name: `ToggleSwitch`,
    props: {
      options: {
        type: Object,
        required: false,
        default: () => {}
      }
    },
    data() {
      return {
        index: 1,
        items: [
          { name: `${this.$t(`pages.settings.active`)}/${this.$t(`pages.settings.inactive`)}` },
          { name: this.$t(`pages.settings.active`) },
          { name: this.$t(`pages.settings.inactive`) }
        ]
      }
    },
    methods: {
      toggle(event) {
        this.index = this.index === this.items.length - 1 ? 0 : this.index + 1
        this.$emit(`input`, this.index)
      }
    }
  }
