export function subscriptions(t) {
  return [
    {
      name: `Start`,
      title: t(`components.subscription.start`),
      description: t(`components.subscription.start_message`),
      monthlyLicenceFee: `0 CHF`,
      minimumExpenditure: `480 CHF`,
      HRClientUser: `2`,
      dossiersPerMonth: `1-9`,
      marketplace: `mdi-close-circle`,
      marketplaceComponents: `icon`,
      textDossiersPerMonth: t(`components.subscription.dossiers_per_year`),
      clientBusinessUnits: `1`,
      customizedProgrammesComponents: `icon`,
      customizedProgrammes: `mdi-close-circle`,
      hrClientUserExperience: {
        dossierStart: t(`components.subscription.48h`),
        HRAnalyticsDashboard: `mdi-close-circle`,
        customizedAlerts: `mdi-close-circle`,
        customizedQuestionnaires: `mdi-close-circle`,
        clientSuccessManager: `mdi-close-circle`,
        HRanalyticsManagaer: `mdi-close-circle`
      },
      employeeExperience: {
        DedicatedFileManager: `mdi-check-circle`,
        ReimbursementService: `mdi-close-circle`,
        HRFileManagerSupport: `mdi-check-circle`,
        standardisedAlerts: `mdi-check-circle`,
        customizedAlerts: `mdi-close-circle`,
        employerBrandedPlatform: `mdi-close-circle`
      },
      ITSecurity: {
        sso: `mdi-close-circle`,
        apiIntegration: `mdi-close-circle`,
        ipAccessControl: `mdi-close-circle`,
        ITDevelopment: `mdi-close-circle`,
        ISO27001Certification: `mdi-check-circle`,
        dataHostingInSwitzerland: `mdi-check-circle`,
        dataDeletion: `mdi-check-circle`,
        employerBrand: `mdi-close-circle`,
        HRISIntegration: `mdi-close-circle`
      }
    },
    {
      name: `Regular`,
      title: t(`components.subscription.Regular`),
      description: t(`components.subscription.regular_message`),
      monthlyLicenceFee: `480 CHF`,
      minimumExpenditure: `0 CHF`,
      HRClientUser: `3`,
      dossiersPerMonth: `10-99`,
      marketplace: `mdi-check-circle`,
      marketplaceComponents: `icon`,
      textDossiersPerMonth: t(`components.subscription.dossiers_per_year`),
      clientBusinessUnits: `1`,
      customizedProgrammes: t(`components.subscription.option`),
      hrClientUserExperience: {
        dossierStart: t(`components.subscription.48h`),
        HRAnalyticsDashboard: `mdi-check-circle`,
        customizedAlerts: `mdi-close-circle`,
        customizedQuestionnaires: `mdi-close-circle`,
        clientSuccessManager: `mdi-close-circle`,
        HRanalyticsManagaer: `mdi-close-circle`
      },
      employeeExperience: {
        DedicatedFileManager: `mdi-check-circle`,
        ReimbursementService: `mdi-check-circle`,
        HRFileManagerSupport: `mdi-check-circle`,
        standardisedAlerts: `mdi-check-circle`,
        customizedAlerts: `mdi-close-circle`,
        employerBrandedPlatform: `mdi-close-circle`
      },
      ITSecurity: {
        ssoComponents: `text`,
        apiIntegrationComponents: `text`,
        ITDevelopmentComponents: `text`,
        sso: t(`components.subscription.option`),
        apiIntegration: t(`components.subscription.option`),
        ipAccessControl: `mdi-close-circle`,
        ITDevelopment: t(`components.subscription.option`),
        ISO27001Certification: `mdi-check-circle`,
        dataHostingInSwitzerland: `mdi-check-circle`,
        dataDeletion: `mdi-check-circle`,
        employerBrand: `mdi-close-circle`,
        HRISIntegration: `mdi-close-circle`
      }
    },
    {
      name: `Professional`,
      title: t(`components.subscription.professional`),
      description: t(`components.subscription.professional_message`),
      monthlyLicenceFee: `1980 CHF`,
      minimumExpenditure: `0 CHF`,
      HRClientUser: `9`,
      dossiersPerMonth: `100-249`,
      marketplace: `mdi-check-circle`,
      marketplaceComponents: `icon`,
      textDossiersPerMonth: t(`components.subscription.dossiers_per_year`),
      clientBusinessUnits: `3`,
      customizedProgrammesComponents: `icon`,
      customizedProgrammes: `mdi-check-circle`,
      hrClientUserExperience: {
        dossierStart: t(`components.subscription.24h`),
        HRAnalyticsDashboard: `mdi-check-circle`,
        customizedAlerts: `mdi-check-circle`,
        customizedQuestionnaires: `mdi-check-circle`,
        clientSuccessManager: `mdi-check-circle`,
        HRanalyticsManagaer: `mdi-check-circle`
      },
      employeeExperience: {
        DedicatedFileManager: `mdi-check-circle`,
        ReimbursementService: `mdi-check-circle`,
        HRFileManagerSupport: `mdi-check-circle`,
        standardisedAlerts: `mdi-check-circle`,
        customizedAlerts: `mdi-check-circle`,
        employerBrandedPlatform: `mdi-close-circle`
      },
      ITSecurity: {
        ssoComponents: `mdi-check-circle`,
        apiIntegrationComponents: `text`,
        ipAccessControlComponents: `text`,
        ITDevelopmentComponents: `text`,
        sso: `mdi-check-circle`,
        apiIntegration: t(`components.subscription.option`),
        ipAccessControl: t(`components.subscription.option`),
        ITDevelopment: t(`components.subscription.option`),
        ISO27001Certification: `mdi-check-circle`,
        dataHostingInSwitzerland: `mdi-check-circle`,
        dataDeletion: `mdi-check-circle`,
        employerBrand: `mdi-close-circle`,
        HRISIntegration: `mdi-close-circle`
      }
    },
    {
      name: `Enterprise`,
      title: t(`components.subscription.enterprise`),
      description: t(`components.subscription.enterprise_message`),
      monthlyLicenceFee: `5940 CHF`,
      minimumExpenditure: `0 CHF`,
      HRClientUser: `12`,
      dossiersPerMonth: `250+`,
      marketplace: `mdi-check-circle`,
      marketplaceComponents: `icon`,
      textDossiersPerMonth: t(`components.subscription.dossiers_per_year`),
      clientBusinessUnits: `3`,
      customizedProgrammesComponents: `icon`,
      customizedProgrammes: `mdi-check-circle`,
      hrClientUserExperience: {
        dossierStart: t(`components.subscription.24h`),
        HRAnalyticsDashboard: `mdi-check-circle`,
        customizedAlerts: `mdi-check-circle`,
        customizedQuestionnaires: `mdi-check-circle`,
        clientSuccessManager: `mdi-check-circle`,
        HRanalyticsManagaer: `mdi-check-circle`
      },
      employeeExperience: {
        DedicatedFileManager: `mdi-check-circle`,
        ReimbursementService: `mdi-check-circle`,
        HRFileManagerSupport: `mdi-check-circle`,
        standardisedAlerts: `mdi-check-circle`,
        customizedAlerts: `mdi-check-circle`,
        employerBrandedPlatform: `mdi-check-circle`
      },
      ITSecurity: {
        sso: `mdi-check-circle`,
        apiIntegration: `mdi-check-circle`,
        ipAccessControl: `mdi-check-circle`,
        ITDevelopmentComponents: `text`,
        ITDevelopment: t(`components.subscription.option`),
        ISO27001Certification: `mdi-check-circle`,
        dataHostingInSwitzerland: `mdi-check-circle`,
        dataDeletion: `mdi-check-circle`,
        employerBrand: `mdi-check-circle`,
        HRISIntegration: `mdi-close-circle`
      }
    },
    {
      name: `Corporate`,
      title: t(`components.subscription.corporate`),
      description: t(`components.subscription.corporate_message`),
      monthlyLicenceFee: `Upon request`,
      minimumExpenditure: `0 CHF`,
      HRClientUser: t(`components.subscription.unlimited`),
      dossiersPerMonth: `250+`,
      marketplace: `mdi-check-circle`,
      marketplaceComponents: `icon`,
      textDossiersPerMonth: t(`components.subscription.dossiers_per_year`),
      clientBusinessUnits: t(`components.subscription.unlimited`),
      customizedProgrammesComponents: `icon`,
      customizedProgrammes: `mdi-check-circle`,
      hrClientUserExperience: {
        dossierStart: t(`components.subscription.24h`),
        HRAnalyticsDashboard: `mdi-check-circle`,
        customizedAlerts: `mdi-check-circle`,
        customizedQuestionnaires: `mdi-check-circle`,
        clientSuccessManager: `mdi-check-circle`,
        HRanalyticsManagaer: `mdi-check-circle`
      },
      employeeExperience: {
        DedicatedFileManager: `mdi-check-circle`,
        ReimbursementService: `mdi-check-circle`,
        HRFileManagerSupport: `mdi-check-circle`,
        standardisedAlerts: `mdi-check-circle`,
        customizedAlerts: `mdi-check-circle`,
        employerBrandedPlatform: `mdi-check-circle`
      },
      ITSecurity: {
        sso: `mdi-check-circle`,
        apiIntegration: `mdi-check-circle`,
        ipAccessControl: `mdi-check-circle`,
        ITDevelopmentComponents: `text`,
        ITDevelopment: t(`components.subscription.option`),
        ISO27001Certification: `mdi-check-circle`,
        dataHostingInSwitzerland: `mdi-check-circle`,
        dataDeletion: `mdi-check-circle`,
        employerBrand: `mdi-check-circle`,
        HRISIntegration: `mdi-check-circle`
      }
    }
  ]
}

const subscriptionFactory = {
  subscriptions
}

export default subscriptionFactory
