
  import { mapState } from 'vuex'

  export default {
    name: `CompanySecuritySettings`,
    props: {
      value: {
        type: Object,
        default: null
      },
      formDataSettings: {
        type: Object,
        default: null
      }
    },
    data() {
      return {
        confirmDelModal: false,
        confirmDelLocalStorage: false,
        settings: {
          ...this.value
        }
      }
    },
    computed: {
      isClient() {
        return this.$store.getters[`permissions/userIsClient`]
      },
      isMaster() {
        return this.$store.getters[`permissions/userIsMaster`]
      },
      dataExist() {
        return !localStorage.getItem(`aeq-data`)
      },
      ...mapState({
        allowed_networks: (state) => state.settings.me.security.allowed_networks || [],
        screening_deletion: (state) => state.settings.me.security.screening_deletion_period || 90,
        screening_deletion_period: (state) => state.settings.me.security.screening_deletion_period.period || 90,
        screening_deletion_update: (state) => state.settings.me.security.screening_deletion_period.update || false,
        deletionPeriods: (state) => state.formData.settings.client_deletion_periods,
        has_ip_restriction_feature: (state) => state.settings.me.security.has_ip_restriction_feature || false,
        authentication_method: (state) =>state.settings.me.company_information.authentication_method,
        mfa_method: (state) =>state.settings.me.company_information.mfa_method,

        authentication_methods: (state) => state.formData.settings.authentication_method_choices,
        mfa_methods: (state) => state.formData.settings.mfa_method_choices,
      })
    },
    watch: {
      settings: {
        deep: true,
        handler() {
          this.$emit(`input`, this.settings)
        }
      },
      value: {
        deep: true,
        handler() {
          this.settings = {
            ...this.value
          }
        }
      }
    },
    created() {},
    methods: {
      getFilteredAllowedNetworks() {
        if (
          this.allowed_networks.length === 0 ||
          (this.allowed_networks.length === 1 && this.allowed_networks[0] === `0.0.0.0/0`)
        ) {
          return [this.$i18n.t(`pages.settings.IP_access_restriction_all_allowed`)]
        }
        return this.allowed_networks
      },
      async inputChange(newValue, payload) {
        !!newValue &&
          (await this.$store.commit(`settings/SET_VALUE`, {
            namespace: `security`,
            key: payload.key,
            value: { ...this.screening_deletion, period: newValue }
          }))
      }
    }
  }
