
  import { mapState } from 'vuex'
  import AddCredits from './AddCredits.vue'
  import { emptyBillingAddress } from '~/data-structures/accounting'

  export default {
    name: `AccountingSettings`,
    components: { AddCredits },
    props: {
      value: {
        type: Object,
        default: null
      }
    },
    data() {
      return {
        newBillingAddressModal: false,

        loadingNewBillingAddress: false,
        panel: undefined,
        newBillingAddress: emptyBillingAddress(),

        currentAddressID: null,
        isPeriodInvoice: null,
        currentStates: [],
        newAdresseStates: [],
        isPrepaid: null
      }
    },
    async fetch() {
      await this.$store.dispatch(`accounting/getSettings`, { companyTag: this.companyTag })
      await this.$store.dispatch(`accounting/getAddresses`, { companyTag: this.companyTag })
      await this.$store.dispatch(`formData/billingAddressOptions`)
    },
    computed: {
      ...mapState({
        billingAddress: (state) => JSON.parse(JSON.stringify(state.accounting.addressesList)),
        settings: (state) => JSON.parse(JSON.stringify(state.accounting.settings)),
        accountStatement: (state) =>
          JSON.parse(JSON.stringify(state.formData.billingAddressOptions.account_statement_periods)),
        currency: (state) => JSON.parse(JSON.stringify(state.formData.billingAddressOptions.currencies)),
        address_types: (state) => JSON.parse(JSON.stringify(state.formData.billingAddressOptions.address_types || [])),
        billingPeriods: (state) =>
          JSON.parse(JSON.stringify(state.formData.billingAddressOptions.billing_periods || [])),
        invoiceModes: (state) => JSON.parse(JSON.stringify(state.formData.billingAddressOptions.invoice_modes || [])),
        countries: (state) => JSON.parse(JSON.stringify(state.formData.billingAddressOptions.countries))
      }),
      currentLanguage() {
        return this.$i18n.locale
      },
      companyTag() {
        return this.$auth.user.current_company
      },
      isMaster() {
        return this.$store.getters[`permissions/userIsMaster`]
      }
    },
    watch: {
      'settings.invoice_mode': {
        deep: true,
        handler(newValue) {
          this.isPrepaid = newValue === `prepaid`
          this.isPeriodInvoice = newValue === `period_invoice`
        }
      },
      panel: {
        deep: true,
        handler(newValue) {
          if (typeof newValue !== `undefined`) {
            const currentAddress = this.billingAddress[newValue]
            const tempsState = JSON.parse(JSON.stringify(this.$store.state.formData.billingAddressOptions.states)) || []
            if (tempsState.length > 0) {
              this.currentStates = tempsState
                .filter((state) => state.country === this.billingAddress[newValue].country)
                .map((state) => ({ text: this.parse(state.text)[this.currentLanguage], value: state.value }))
              if (this.currentStates.length === 0) {
                currentAddress.state_iso = undefined
              }
            } else {
              currentAddress.state_iso = undefined
              this.currentStates = []
            }
          } else {
            this.currentStates = []
          }
        }
      }
    },
    methods: {
      async closeModal({ resetValue = false }) {
        await this.$refs.newBillingAddressModalObs.reset()
        this.newBillingAddressModal = false
        !!resetValue && (this.newBillingAddress = {})
      },
      openModal() {
        this.newBillingAddressModal = true
      },

      async addAddress() {
        const ref = this?.$refs.newBillingAddressModalObs
        const addressIsValide = await ref?.validate()
        if (!!addressIsValide) {
          try {
            await this.$store.dispatch(`accounting/newAddress`, {
              companyTag: this.companyTag,
              params: { ...this.newBillingAddress }
            })
            this.addAddressSuccess()
          } catch (error) {
            this.addressCallbackError({
              ref,
              error
            })
          }
        }
      },
      changeState(value) {
        if (typeof this.panel === `undefined`) {
          this.currentStates = []
        } else {
          const currentAddress = this.billingAddress[this.panel]
          const tempsState = JSON.parse(JSON.stringify(this.$store.state.formData.billingAddressOptions.states)) || []
          if (tempsState.length > 0) {
            this.currentStates = tempsState
              .filter((state) => state.country === value)
              .map((state) => ({ text: this.parse(state.text)[this.currentLanguage], value: state.value }))
            if (this.currentStates.length === 0) {
              currentAddress.state_iso = undefined
            }
          } else {
            currentAddress.state_iso = undefined
            this.currentStates = []
          }
        }
      },
      changeNewState(value) {
        if (typeof value === `undefined`) {
          this.newAdresseStates = []
        } else {
          const currentAddress = this.newBillingAddress
          const tempsState = JSON.parse(JSON.stringify(this.$store.state.formData.billingAddressOptions.states)) || []
          if (tempsState.length > 0) {
            this.newAdresseStates = tempsState
              .filter((state) => state.country === value)
              .map((state) => ({ text: this.parse(state.text)[this.currentLanguage], value: state.value }))
            if (this.newAdresseStates.length === 0) {
              currentAddress.state_iso = undefined
            }
          } else {
            currentAddress.state_iso = undefined
            this.newAdresseStates = []
          }
        }
      },
      parse(string) {
        return JSON.parse(string.replaceAll(`"`, `\``).replaceAll(`'`, `"`)) ?? []
      },
      async updateAddress(address) {
        const ref = this?.$refs[`address-${address.uuid}`][0]
        const addressIsValide = await ref?.validate()

        if (!!addressIsValide) {
          try {
            await this.$store.dispatch(`accounting/updateAdress`, {
              companyTag: this.companyTag,
              uuid: address.uuid,
              params: { ...address }
            })
            this.updateAddressCallbackSuccess()
          } catch (error) {
            this.addressCallbackError({
              ref,
              error,
              message: this.$i18n.t(`pages.settings.accounting.messages.address_has_not_been_updated`)
            })
          }
        }
      },
      addAddressSuccess() {
        this.$store.dispatch(
          `flashMessages/messageSuccess`,
          { message: this.$i18n.t(`pages.settings.accounting.messages.the_address_has_been_added`) },
          { root: true }
        )
        this.closeModal({ resetValue: true })
        this.$store.dispatch(`accounting/getAddresses`, { companyTag: this.companyTag })
      },
      addAddressError() {},
      updateAddressCallbackSuccess() {
        this.$store.dispatch(
          `flashMessages/messageSuccess`,
          { message: this.$i18n.t(`pages.settings.accounting.messages.the_address_has_been_updated`) },
          { root: true }
        )
        this.$store.dispatch(`accounting/getAddresses`, { companyTag: this.companyTag })
      },
      async addressCallbackError({ ref, error }) {
        const errorField = error?.response?.data
        await ref.setErrors(errorField)
      },
      updateAccountStatement(event) {
        const params = { account_statement: event }
        this.$store
          .dispatch(`accounting/updateSettings`, { companyTag: this.companyTag, params })
          .then(
            async () =>
              await this.$store.dispatch(`flashMessages/messageSuccess`, {
                message: this.$i18n.t(
                  `pages.settings.accounting.messages.the_account_statement_period_has_been_updated`
                )
              })
          )
          .catch(
            async () =>
              await this.$store.dispatch(`flashMessages/messageError`, {
                message: this.$i18n.t(
                  `pages.settings.accounting.messages.the_account_statement_period_has_not_been_updated`
                )
              })
          )
      }
    }
  }
