
  import { mapGetters } from 'vuex' // import SubscriptionContentV2 from '~/components/subscription/SubscriptionContentV2.vue'
  import { subscriptions } from '~/data-structures/subscriptions'

  export default {
    name: `SubscriptionSettings`,
    components: {
      // SubscriptionContentV2
    },
    props: {
      value: {
        type: [String, Object],
        default: `Regular`
      },
      baseData: {
        type: Object,
        default: null
      },
      disable: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        index: 0,
        features: {},
        columnActive: null,
        loading: true,
        accountTypes: {},
        listener: false,
        subscriptionListVariable: null,
        subscriptionList: subscriptions((key, value) => this.$t(key, value))
      }
    },
    async fetch() {
      try {
        const response = await this.$api.subscriptions.getList()
        this.subscriptionListVariable = response
      } catch (error) {
        console.error(`Error fetching chart data:`, error)
      }
    },
    computed: {
      ...mapGetters({
        isGuest: `permissions/userIsGuest`,
        isMaster: `permissions/userIsMaster`
      }),
      currentSubscription() {
        return this.$store.state.settings.me.subscription.name
      }
    },
    async created() {
      this.baseData === null ? await this.initWithRequest() : this.initWithProps()
    },
    async updated() {
      await this.removeSelectedClass()
      this.onload()
    },
    methods: {
      mouseOverColumn(event) {
        if (event.target.tagName === `TD`) {
          const columnIndex = event.target.cellIndex + 1
          if (!!this.columnActive && this.columnActive !== columnIndex) {
            const rows = this.$refs.dataTable.querySelectorAll(`tr`)
            rows.forEach((row) => {
              const cell = row.querySelector(`td:nth-child(${this.columnActive})`)
              if (cell) {
                cell.classList.remove(`hover`)
              }
            })
          }
          const rows = this.$refs.dataTable.querySelectorAll(`tr`)
          rows.forEach((row) => {
            const cell = row.querySelector(`td:nth-child(${columnIndex})`)
            if (cell) {
              cell.classList.add(`hover`)
            }
          })
          this.columnActive = columnIndex
        }
      },
      mouseLeaveColumn(event) {
        if (!!this.columnActive) {
          const rows = this.$refs.dataTable.querySelectorAll(`tr`)
          rows.forEach((row) => {
            const cell = row.querySelector(`td:nth-child(${this.columnActive})`)
            if (cell) {
              cell.classList.remove(`hover`)
            }
          })
        }
      },
      clickOnColumn(event) {
        if (event.target.tagName === `TD`) {
          const columnIndex = event.target.cellIndex - 1
          const name = this.subscriptionListVariable[columnIndex].name
          this.selectType({ name })
        }
      },
      removeSelectedClass() {
        const rows = this.$refs.dataTable.querySelectorAll(`tr`)
        rows.forEach((row) => {
          const cell = row.querySelectorAll(`td`)
          cell.forEach((cell) => {
            cell.classList.remove(`selected`)
          })
        })
      },
      onload() {
        const index = this.subscriptionList.findIndex((subscription) => subscription.name === this.currentSubscription)
        if (index !== -1) {
          const rows = this.$refs.dataTable.querySelectorAll(`tr`)
          rows.forEach((row) => {
            const cell = row.querySelector(`td:nth-child(${index + 2})`)
            if (cell) {
              cell.classList.add(`selected`)
            }
          })
        }
      },
      selectType({ name }) {
        this.$emit(`input`, { ...this.value, name })
      },
      async initWithRequest() {
        try {
          const data = await this.$api.componentData.getAccountType()
          ;({ features: this.features, account_types: this.accountTypes } = data)
          this.loading = false
          return data
        } catch (err) {
          console.error(err)
          this.errorMessage()
        }
      },
      isActive(accountType) {
        const { selectable } = accountType
        return selectable && { click: () => this.selectType({ ...accountType }) }
      },
      initWithProps() {
        ;({ features: this.features, account_types: this.accountTypes } = this.baseData)
        this.loading = false
      },
      setErrors(errors) {
        if (!!errors) {
          this.$refs.subscriptionObs.setErrors(errors)
        }
      },
      errorMessage(message = this.$t(`messages.unknown_error`), title = this.$t(`messages.error`)) {
        this.$flashMessage.error({
          title,
          message
        })
      }
    }
  }
