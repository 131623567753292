export function headerTransactions() {
  return [
    {
      text: `components.accounting.date`,
      align: `start`,
      sortable: true,
      value: `date`
    },
    { text: `components.accounting.debitCredit`, value: `debitCredit`, sortable: false },
    { text: `components.accounting.balance`, value: `balance`, sortable: false },
    { text: `components.accounting.transaction`, value: `description`, sortable: false },
    { text: `components.accounting.description`, value: `object`, sortable: false },
    { text: `components.accounting.department`, value: `department`, sortable: false },
    { text: `components.accounting.notesClient`, value: `note_for_client`, sortable: false },
    { text: `components.accounting.invoice_number`, value: `invoice_number`, sortable: false },
    { text: `components.accounting.created_by`, value: `created_by`, sortable: false }
  ]
}

export function headerInvoices() {
  return [
    {
      text: `components.accounting.date`,
      align: `start`,
      sortable: true,
      value: `creation_date`
    },
    { text: `components.accounting.invoice_number`, value: `invoice_number`, sortable: false },
    { text: `components.accounting.payment_date`, value: `payment_date`, sortable: false },
    { text: `components.accounting.status`, value: `status`, sortable: false },
    { text: `components.accounting.download_document_link`, value: `download_document_link`, sortable: false },
    // { text: 'components.accounting.created_by', value: 'created_by', sortable: false },
    { text: `components.accounting.amount`, value: `amount`, sortable: false }
  ]
}

export function accountingTabs() {
  return {
    '#invoices': {
      name: `components.accounting.invoices`,
      icon: `mdi-credit-card-edit-outline`,
      api: `/invoices/`,
      namespace: `invoices`,
      componentType: `accounting-component`,
      header: headerInvoices(),
      documentLink: `invoices/export`,
      search_placeholder: `components.accounting.search_invoices`
    },
    // '#parent-child': {
    //   name: 'parent child',
    //   icon: 'mdi-domain',
    //   api: 'getParentChild',
    //   namespace: 'parentChild',
    //   componentType: 'Invoice',
    // },
    '#transactions': {
      name: `components.accounting.transactions`,
      icon: `mdi-credit-card-search-outline`,
      api: `/transactions/`,
      namespace: `transactions`,
      componentType: `accounting-component`,
      header: headerTransactions(),
      documentLink: `transactions/export`,
      search_placeholder: `components.accounting.search_transactions`,
      addCredits: true
    },
    '#settings': {
      name: `components.accounting.settings`,
      icon: `mdi-credit-card-refund-outline`,
      api: `//`,
      namespace: `credits`,
      componentType: `accounting-settings`
    }
  }
}

export const fakeTransactions = () => {
  return [
    {
      date: `2022-03-08 10:08:21.865992+00:00`,
      debitCredit: 159,
      accountBalance: 12930,
      description: `productName`,
      dossier: `(Title + First Name + Last Name + DOB) – filled only if dossier related`,
      department: `Cost Center – filled only if dossier related`,
      notesClient: `(depends on product: invoice number,  )`,
      notesAEQ: ``,
      invoice_number: `123_testnumber`
    },
    {
      date: `2022-03-03 10:08:21.865992+00:00`,
      debitCredit: -159,
      accountBalance: 12771,
      description: `productName`,
      dossier: `(Title + First Name + Last Name + DOB) – filled only if dossier related`,
      department: `Cost Center – filled only if dossier related`,
      notesClient: `(depends on product: invoice number,  )`,
      notesAEQ: ``,
      invoice_number: `123_testnumber`
    }
  ]
}

export function emptyBillingAddress() {
  return {
    type: `billing`,
    type_description: ``,
    department: ``,
    street_1: ``,
    street_2: ``,
    zip: ``,
    city: ``,
    state: ``,
    state_text: ``,
    country: ``,
    email: ``
  }
}

export function billingCurrency() {
  return [
    { text: `CHF`, value: `CHF` },
    { text: `EUR`, value: `EUR` },
    { text: `GBP`, value: `GBP` },
    { text: `USD`, value: `USD` }
  ]
}

const accounting = {
  headerTransactions,
  headerInvoices,
  accountingTabs,
  fakeTransactions,
  emptyBillingAddress,
  billingCurrency
}

export default accounting
