
  import { mapState } from 'vuex'

  export default {
    name: `MyOrganisationSettings`,
    props: {
      value: {
        type: Object,
        default: null
      }
    },
    data() {
      return {
        alerts: null
      }
    },
    computed: {
      currentCompany() {
        return this.$store.getters[`companies/getCurrentCompanyInfos`]
      },
      isMaster() {
        return this.$store.getters[`permissions/userIsMaster`]
      },
      ...mapState({
        masterUser: (state) => state.settings.me.master_user,
        industry: (state) => state.settings.me.company_information.industry,
        industry_company_type: (state) => state.settings.me.company_information.industry_company_type,
        ats_type: (state) => state.settings.me.company_information.ats_type,
        hris_type: (state) =>state.settings.me.company_information.hris_type,
        size: (state) => state.settings.me.company_information.size,
        website: (state) => state.settings.me.company_information.website,
        company_sizes: (state) => state.formData.settings.company_sizes,
        industry_choices: (state) => state.formData.settings.industry_choices,
        industry_company_type_choices: (state) => state.formData.settings.industry_company_type_choices,
        ats_choices: (state) => state.formData.settings.ats_choices,
        hris_choices: (state) => state.formData.settings.hris_choices
      })
    },
    watch: {
      profile: {
        deep: true,
        handler() {
          this.$emit(`input`, this.profile)
        }
      }
    },
    methods: {
      async inputBlur(event, { key, namespace = `company_information` }) {
        const value = event?.target?.type === `text` ? event.target.value : typeof event === `string` ? event : ``
        await this.$store.commit(`settings/SET_VALUE`, { namespace, key, value })
      },
      // async telInputChange(value, { number: { international }, isValid, country }) {
      //   if (!isValid) {
      //     return
      //   }
      //   await this.$store.commit('settings/SET_VALUE', { namespace: 'profile', key: 'phone', value: international })
      // },
      setErrors(errors) {
        if (!!errors) {
          this.$refs.organisationObs.setErrors(errors)
        }
      }
    }
  }
