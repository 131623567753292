
  import { mapState } from 'vuex'

  export default {
    name: `MyReports`,
    components: {  },
    props: {
    },
    data() {
      return {
      }
    },
    computed: {
      ...mapState({
        report_periods: (state) => state.formData.settings.report_periods,
        accounting_period: (state) => state.settings.me.report_settings.accounting,
        screening_time_period: (state) => state.settings.me.report_settings.screening_time,
        dossier_analysis_period: (state) => state.settings.me.report_settings.dossier_analysis,
        result_analysis_period: (state) => state.settings.me.report_settings.result_analysis,
        progress_period: (state) => state.settings.me.report_settings.progress,
      }),
    },
    methods: {
      async inputBlur(event, { key, namespace = `report_settings` }) {
        const value = event?.target?.type === `text` ? event.target.value : typeof event === `string` ? event : ``
        await this.$store.commit(`settings/SET_VALUE`, { namespace, key, value })
      },
    }
  }
